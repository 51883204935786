import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Register from '../views/Register.vue'
import ListarCollegiados from '../views/collegiates/ListarCollegiados.vue'
import AddCollegiate from '../views/collegiates/AddCollegiate.vue'


const routes = [
  {
    path: '/',
    name: 'registrar',
    component: AddCollegiate
  },
  
  {
    path: '/listarColegiados',
    name: '/listarColegiados',
    component: ListarCollegiados
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
