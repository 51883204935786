<template>
  <br>
  <div class="container">
    <div class="row justify-content-center">
      <!-- Columna para dispositivos grandes (md y superiores) -->
      <div class="col-md-5 order-md-1 mb-4">
        <div class="rounded p-3" style="background-color: #ffcee9;">
          <p style="text-align: justify; text-indent: 20px;">El Consejo Directivo del Colegio de Contadores Públicos de La Libertad 2024-2025, se complace en invitar a Ud. a celebrar el día de la madre contadora, destacando su noble labor y dedicación en nuestras familias.<br><b>*Evento exclusivo para madres contadoras hábiles o vitalicias o madres de contadores hábiles.</b><br><b>*SOLO 1 TICKET POR REGISTRO.</b></p>
          <p style="text-align: justify;">
            <i class="fas fa-calendar-alt"></i> Sábado 11 de Mayo<br>
            <i class="fas fa-clock"></i> 4:30 pm<br>
            <i class="fas fa-map-marker-alt"></i>  Auditorio Ramón Chumán Local Villa del Contador<br>
            <i class="fas fa-users"></i> <b>Aforo:</b> 400 personas
          </p>
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            Cupos Disponibles : {{ cupos }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
      </div>

      <!-- Columna para dispositivos pequeños (sm y menores) -->
      <div class="col-10 col-md-5 order-md-2">
        <form @submit.prevent="buscarColegiado">
          <!-- Campos de búsqueda -->
          <div class="mb-3">
            <label for="documento" class="form-label">Documento de Identidad del Miembro de la Orden</label>
            <input v-model="documento" type="text" class="form-control" id="documento" aria-describedby="documentoHelp">
            <div id="documentoHelp" class="form-text">*Recuerda estar hábil para inscribirte</div>
          </div>
          <button type="submit" class="btn btn-primary">Buscar</button>
          <br><br>
          <!-- Campos de información del colegiado (siempre deshabilitados) -->
          <div class="mb-3">
            <label for="nombre" class="form-label">Nombre</label>
            <input v-model="nombre" type="text" class="form-control" id="nombre" aria-describedby="nombreHelp" disabled>
          </div>
          <div class="mb-3">
            <label for="estado" class="form-label">Estado</label>
            <input v-model="estado" type="text" class="form-control" id="estado" aria-describedby="estadoHelp" disabled>
          </div>
          <!-- Botón de inscripción -->
          <button @click.prevent="crearAsistente" class="btn btn-success">Inscribirse</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      // colegiado: null,
      documento: '',
      nombre: '',
      estado: '',
      apiUrl: '',// Variable para la URL de la API
      cupos: 0
    };
  },
  created() {
    // Determina la URL de la API basándose en el entorno
    if (process.env.NODE_ENV === 'production') {
      this.apiUrl = 'https://inscripcioneventosapi.ccpll.org/api';
    } else {
      this.apiUrl = 'http://localhost:8000/api';
    }
  },
  mounted() {
    this.cuposRestantes();
  },
  methods: {
    cuposRestantes() {
      axios.get(`${this.apiUrl}/contarColegiados`)
        .then(response => {
          // Procesar la respuesta aquí
          this.cupos = 400 - response.data
        })
        .catch(error => {
          // Manejar errores de solicitud aquí
          //console.error('Error al realizar la solicitud:', error);
        });

    },
    buscarColegiado() {
      Swal.fire({
        title: 'Buscando información...',
        html: 'Por favor, espera un momento mientras se buscan los datos.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      axios.get(`https://server.ccpll.org/api/listcolegiados/${this.documento}`)
        .then(response => {
          // Verifica si la respuesta contiene datos
          if (response.data) {
            // Accede a los atributos _ESTADO_COLEGIADO y _DATOS_PERSONALES
            this.nombre = response.data._DATOS_PERSONALES;
            this.estado = response.data._ESTADO_COLEGIADO;
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Información encontrada con éxito.',
              showConfirmButton: false,
              timer: 2000
            })
          } else {
            // Manejar el caso en que la respuesta no tenga datos
            Swal.fire({
              icon: 'error',
              title: 'Colegiado no encontrado',
              text: 'Por favor, ingrese correctamente el documento de identidad'
            });
            this.documento = '';
            this.nombre = '';
            this.estado = '';
          }
        })
        .catch(error => {
          // Manejar errores de solicitud
          //console.error('Error al buscar el colegiado:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error al buscar el colegiado',
            text: 'Ocurrió un error al intentar buscar el colegiado. Por favor, inténtalo de nuevo más tarde.'
          });
        });
    },
    crearAsistente() {
      Swal.fire({
        title: 'Procesando Solicitud...',
        html: 'Por favor, espera un momento mientras se procesa su solicitud.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      // Llama a la API para crear un asistente utilizando los datos del formulario
      axios.post(`${this.apiUrl}/crearAsistente`, {

        condicion: this.estado,
        doiCollegiate: this.documento,
        nameCollegiate: this.nombre
        // Otros campos necesarios para crear un registro de colegiado
      })
        .then(response => {
          // console.log('Asistente creado exitosamente:', response.data);
          Swal.fire({
            icon: 'success',
            title: 'Registro Exitoso',
            html: `Ha sido rigistrado exitosamente<br> <b>${response.data.mensaje}<b>`
          });
          // Limpiar campos del formulario
          this.documento = '';
          this.nombre = '';
          this.estado = '';
          this.cuposRestantes();
          // Realizar acciones adicionales después de crear el asistente si es necesario
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error al Registrar',
            text: error.response.data.mensaje
          });
          this.documento = '';
          this.nombre = '';
          this.estado = '';
        });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>